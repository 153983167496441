<template>
  <div class="head-bar">
    <div class="fixed-box row-center">
      <div class="section-box head-bar-content">
        <div class="head">
          <img class="logo" src="@/assets/common/logo2.png" alt="" />
        </div>
        <div class="body">
          <div class="menu">
            <template v-for="(item, index) in list">
              <router-link :key="`item${index}`" :to="{ name: item.link }">
                <div :class="['menu-item', $route.name === item.link ? 'active' : '']">
                  {{ item.label }}
                </div>
              </router-link>
            </template>
          </div>
        </div>
        <div class="foot" @click="visible = true"></div>
      </div>
    </div>
    <div class="fixed-box popup-menu" v-if="visible">
      <div class="popup-menu-content">
        <div class="popup-menu-head" @click="visible = false">
          <img class="close" src="@/assets/common/menu2.png" alt="" />
        </div>
        <div class="popup-menu-body" @click="visible = false">
          <template v-for="(item, index) in list">
            <router-link :key="`popup-item${index}`" :to="{ name: item.link }">
              <div :class="['popup-menu-item', $route.name === item.link ? 'active' : '']">
                {{ item.label }}
              </div>
            </router-link>
          </template>
        </div>
        <div class="popup-menu-foot row-center">
          <img class="logo" src="@/assets/common/logo2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadBar",
  data() {
    return {
      visible: false,
      list: [
        { label: "首页", link: "home" },
        { label: "关于我们", link: "about" },
        { label: "系列产品", link: "products" },
        { label: "新闻资讯", link: "news" },
        { label: "联系我们", link: "contact" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .head-bar {
    height: 1.2rem;
    .fixed-box {
      width: 100%;
      background: #fff;
      position: fixed;
      z-index: 999;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.62rem 0 0.6rem;
      box-sizing: border-box;
      position: relative;
      .head {
        .logo {
          width: 1.87rem;
          height: 0.6rem;
        }
      }
      .body {
        display: none;
        .menu {
          &-item {
            width: 140px;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #333333;
            line-height: 24px;
            transition: all 0.2s ease-in;
            box-sizing: border-box;
            border-bottom: 4px solid #fff;
          }
          .active {
            font-family: PingFangSC-Medium;
            color: #e76d00;
            border-color: #e76d00;
          }
        }
      }
      .foot {
        background: url("../../assets/common/menu1.png") no-repeat center;
        background-size: cover;
        width: 0.5rem;
        height: 0.3rem;
      }
    }
    .popup-menu {
      height: 100%;
      background: rgba(0, 0, 0, 0.41);
      &-head {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0.9rem 0.62rem 0.63rem 0rem;
        box-sizing: border-box;
        .close {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      &-content {
        float: right;
        background: #f7f7f7;
        border-radius: 0 0 0 0.8rem;
        width: 3.98rem;
        max-height: 100%;
        overflow: hidden;
      }
      &-item {
        padding: 0.28rem 0 0.2rem 0.44rem;
        box-sizing: border-box;
        font-family: PingFangSC-Regular;
        font-size: 0.32rem;
        color: #5b5b5b;
        text-align: left;
        border-bottom: 0.02rem solid rgba(230, 229, 229, 0.17);
        box-sizing: border-box;
      }
      .active {
        font-family: PingFangSC-Medium;
        color: #e76d00;
      }
      &-foot {
        padding: 0.85rem 0 1.22rem;
        box-sizing: border-box;
        .logo {
          width: 1.87rem;
          height: 0.6rem;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .head-bar {
    height: 180px;
    .fixed-box {
      width: 100%;
      background: #fff;
      position: fixed;
      z-index: 999;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .head {
        .logo {
          width: 254px;
          height: 79px;
        }
      }
      .body {
        .menu {
          display: flex;
          &-item {
            width: 140px;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #333333;
            line-height: 24px;
            transition: all 0.2s ease-in;
            box-sizing: border-box;
            border-bottom: 4px solid #fff;
          }
          .active {
            font-family: PingFangSC-Medium;
            color: #e76d00;
            border-color: #e76d00;
          }
        }
      }
      .foot {
        display: none;
      }
    }
    .popup-menu {
      display: none;
    }
  }
}
</style>
