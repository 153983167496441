<template>
  <div class="foot-bar row-center">
    <div class="section-box foot-bar-content">
      <div class="head col-center">
        <img class="logo" src="@/assets/common/logo1.png" alt="" />
        <span class="title">让中华最好的料理走进千家万户</span>
      </div>
      <div class="body">
        <div class="body-layer">
          <div class="cell-item">
            <strong class="label">联系电话</strong>
            <a class="value" href="tel:020-32640047">020-32640047</a>
          </div>
          <div class="cell-item">
            <strong class="label">联系邮箱</strong>
            <a class="value" href="mailto:admin@xianweiyeye.com">admin@xianweiyeye.com</a>
          </div>
        </div>
        <div class="cell-item">
          <strong class="label">联系地址</strong>
          <span class="value">广东省广州市海珠区新滘中路（海珠同创汇）西五街66号之一整栋</span>
        </div>
      </div>
      <div class="foot col-center">
        <img class="search" src="@/assets/common/search.png" alt="" />
        <div class="icon-box">
          <a class="icon" href="https://v.douyin.com/dRQ1yRr/">
            <img src="@/assets/common/dy.png" alt="" />
            <img class="qrcode" src="@/assets/common/dy-qrcode.jpg" alt="" />
          </a>
          <a
            class="icon"
            href="https://pangdangjia.tmall.com/?spm=a220o.1000855.w5001-23402521143.2.6ac01d20KvzCQw&scene=taobao_shop"
            target="_black"
          >
            <img src="@/assets/common/tb.png" alt="" />
          </a>
          <a class="icon" href="https://mall.jd.com/index-10703888.html" target="_black">
            <img src="@/assets/common/jd.png" alt="" />
          </a>
          <a
            class="icon"
            :class="{
              active: wxActive
            }"
            href="javascript:"
            @click="wxActive = !wxActive"
          >
            <img src="@/assets/common/wx.png" alt="" />
            <img class="qrcode" src="@/assets/common/wx-qrcode.jpg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootBar",
  data() {
    return {
      wxActive: false
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .foot-bar {
    background: #e76d01;
    width: 100%;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.42rem 0.6rem 0.26rem;
      box-sizing: border-box;
      .head {
        display: none;
      }
      .body {
        &-layer {
          display: flex;
          flex-direction: column;
        }
        .cell-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.1rem;
          .label,
          .value {
            font-family: PingFangSC-Regular;
            font-size: 0.18rem;
            line-height: 0.28rem;
            color: #ffffff;
            text-align: left;
          }
        }
      }
      .foot {
        flex: 0 0 auto;
        width: 3rem;
        .search {
          width: 100%;
          height: 0.42rem;
        }
        .icon-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 0.22rem;
          .icon {
            width: 0.56rem;
            height: 0.56rem;
            position: relative;
            font-size: 0;
            & > img {
              width: 100%;
              height: 100%;
            }
            .qrcode {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
              width: 1.8rem;
              height: 1.8rem;
              opacity: 0;
              transition: opacity 500ms ease-in;
            }
          }
          .active {
            .qrcode {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .foot-bar {
    background: #e76d01;
    width: 100%;
    padding: 60px 0;
    box-sizing: border-box;
    &-content {
      display: flex;
      justify-content: space-between;
      .head {
        flex: 0 0 auto;
        .logo {
          width: 219px;
          height: 68px;
        }
        .title {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
          line-height: 22px;
          margin-top: 8px;
        }
      }
      .body {
        width: 490px;
        .body-layer {
          margin-bottom: 30px;
          display: flex;
          .cell-item {
            width: 50%;
          }
        }
        .cell-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .label,
          .value {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
      .foot {
        flex: 0 0 auto;
        width: 300px;
        .search {
          width: 100%;
          height: 42px;
        }
        .icon-box {
          margin-top: 22px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .icon {
            width: 56px;
            height: 56px;
            position: relative;
            &:hover {
              .qrcode {
                opacity: 1;
              }
            }
            & > img {
              width: 100%;
              height: 100%;
            }
            .qrcode {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -100%);
              z-index: 2;
              width: 180px;
              height: 180px;
              opacity: 0;
              transition: opacity 500ms ease-in;
            }
          }
        }
      }
    }
  }
}
</style>
