<template>
  <div id="app">
    <head-bar />
    <transition name="slide">
      <router-view />
    </transition>
    <foot-bar />
    <record-bar />
  </div>
</template>

<script>
import headBar from "@/components/headBar";
import footBar from "@/components/footBar";
import recordBar from "@/components/recordBar";

export default {
  name: "App",
  components: {
    headBar,
    footBar,
    recordBar
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
}

a {
  text-decoration: none;
}

#app {
  font-family: PingFangSC-Light, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .section-box {
    width: 7.5rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 750px) {
  * {
    font-size: 0;
  }
  .section-box {
    width: 1190px;
  }
}
</style>
