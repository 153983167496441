<template>
  <div class="record-bar row-center">
    <div class="section-box record-bar-content">
      <p>
        ©2021&nbsp;xianweiyeye.com.&nbsp;All&nbsp;Rights
        Reserved.&nbsp;&nbsp;仙味爷爷（广州）食品科技有限公司&nbsp;&nbsp;版权所有&nbsp;
        <a href="https://beian.miit.gov.cn" rel="nofollow" target="_blank">
          粤ICP备2021114355号
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordBar"
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .record-bar {
    &-content {
      padding: 0.2rem;
      box-sizing: border-box;
      & > p {
        margin: 0;
        font-size: 0.14rem;
        color: #333;
        & > a {
          font-size: 0.14rem;
          color: #333;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .record-bar {
    &-content {
      padding: 20px;
      box-sizing: border-box;
      & > p {
        margin: 0;
        font-size: 14px;
        color: #333;
        & > a {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
}
</style>
